import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import serviceBg from "../../assets/images/backgrounds/service-hand-bg-1-1.png";
import serviceLine from "../../assets/images/shapes/service-line-1-1.png";
import blockTitleHeart from "../../assets/images/shapes/heart-2-1.png";

const serviceOneData = [
  {
    icon: "mechanika",
    extraClassName: "background-secondary",
    title: "Mechanika",
    text: "Naprawiamy różne auta...",
    link: "/causes"   
  },
  {
    icon: "diagnostyka",
    title: "Diagnostyka",
    extraClassName: "background-base",
    text: "Oferujemt usługi diagnostyczne...",
    link: "/causes"
  },
  {
    icon: "opony",
    title: "Opony / przechowalnia",
    text: "Wymiana i przechowywanie opon...",
    link: "/causes",
    extraClassName: "background-primary"
  },
  {
    icon: "klimatyzacja",
    title: "Klimatyzacja",
    extraClassName: "background-special",
    text: "Pełna obsługa w zakresie klimatyzacji...",
    link: "/causes"
  }
	
];

const ServiceOne = () => {
  return (
    <section
      className="service-one pt-120 pb-90"
      style={{ backgroundImage: `url(${serviceBg})` }}
    >
      <Container>
        <img src={serviceLine} alt="" className="service-one__shape-1" />
        <div className="block-title">
          <p>
            <img src={blockTitleHeart} width="50" alt="" />
            Witamy w naszym warsztacie!
          </p>
          <h3>
            Solidna naprawa <br /> w szerokim zakresie usług.
          </h3>
        </div>
        <Row>
          {serviceOneData.map(
            ({ icon, title, text, link, extraClassName }, index) => (
              <Col md={6} lg={3} key={`service-one-key-${index}`}>
                <div className={`service-one__box`}>
                  <div className={`service-one__icon ${extraClassName}`}>
                    <div className="service-one__icon-inner">
                      <i className={icon}></i>
                    </div>
                  </div>
                  <h3>

                    <Link to={link}>{title}</Link>


                  </h3>
                  <p>{text}</p>
                </div>
              </Col>
            )
          )}
        </Row>
      </Container>
    </section>

  );
};

export default ServiceOne;
