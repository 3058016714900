import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import bgImage from "../../assets/images/backgrounds/page-header-1-1.jpg";
import HeartImage from "../../assets/images/shapes/heart-2-1.png";

const CallToActionTwo = () => {
  return (
    <section className="call-to-action-two">
      <div
        className="call-to-action-two__bg"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <Container className="container pt-80 pb-80">
        <div className="call-to-action-two__icon"><i class="fas fa-eye"></i></div>

        <Row className=" align-items-center">
          <Col lg={7}>
            <div className="block-title">
              <p>
                <img src={HeartImage} width="50" alt="" />
                Zobacz nasz warsztat
              </p>
              <h3>
                W naszym warsztacie <br /> utrzymujemy najwyższy <br /> standard
              </h3>
            </div>
          </Col>
          <Col
            lg={5}
            className=" d-flex justify-content-start justify-content-lg-end"
          >
            <div className="btn-wrap">
              <Link className="scroll-to-target thm-btn" to="/gallery">
                Zobacz warsztat
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToActionTwo;
