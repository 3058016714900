import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";

import banner1 from "../../assets/images/main-slider/slider-1-1.webp";
import banner2 from "../../assets/images/main-slider/slider-1-2.webp";
import banner3 from "../../assets/images/main-slider/slider-2-1.webp";
SwiperCore.use([Autoplay, Pagination, EffectFade]);

const MainSlider = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    pagination: {
      el: "#main-slider-pagination",
      type: "bullets",
      clickable: true
    },
    autoplay: {
      delay: 5000
    }
  };
  return (
	   
    <section className="main-slider">
	  
      <Swiper {...mainSlideOptions}>
	  
        <SwiperSlide> 
	   
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner1})` }}
          ><div className="slider-opacity"></div></div>

          <Container>
            <Row className="row justify-content-end">
              <Col xl={7} lg={12} className="text-right">
                <p>Oferujemy Państwu wysoką <br />jakość obsługi i świadczonych usług</p>
                <h2>
                  Wysoka<br /> jakość obsługi
                </h2>
                <a
                  href="/causes"
                  data-target=".donate-options"
                  className="scroll-to-target thm-btn"
                >
                  Nasze usługi
                </a>

              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner2})` }}
          ><div className="slider-opacity"></div></div>

          <Container>
            <Row className="row justify-content-end">
              <Col xl={8} lg={12} className="text-right">
                <p>Wyjaśniamy i wspólnie ustalamy <br />co dokładnie wymaga naprawy <br />w Państwa samochodzie</p>
                <h2>
                  Przejrzysty<br /> plan napraw
                </h2>
                <a
                  href="/about"
                  data-target=".donate-options"
                  className="scroll-to-target thm-btn "
                >
                 O Nas
                </a>

              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner3})` }}
          ><div className="slider-opacity"></div></div>

          <Container>
            <Row className="justify-content-end">
              <Col lg={7} className=" text-right">
                <p>Wspólnie z Klientem ustalamy<br />  optymalny zakres usług</p>
                <h2>
                  Dopasowany<br /> zakres usług
                </h2>
                 <a
                  href="/causes"
                  data-target=".donate-options"
                  className="scroll-to-target thm-btn"
                >
                  Nasze usługi
                </a>

              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <div className="swiper-pagination" id="main-slider-pagination"></div>
      </Swiper>
    </section>
  );
};

export default MainSlider;
